.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pill {
  margin: 0 0px;
  padding: 4px 8px;
  margin: 0px 2px;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 500;
}
.legend-0 {
  background-color: #A8DADC;
  color: #333333; /* Dark Grey */
}

.legend-1 {
  background-color: #457B9D;
  color: #F5F5F5; /* Soft Off-White */
}

.legend-2 {
  background-color: #E63946;
  color: #F5F5F5; /* Soft Off-White */
}

.legend-3 {
  background-color: #F1FAEE;
  color: #333333; /* Dark Grey */
}

.legend-4 {
  background-color: #1D3557;
  color: #F5F5F5; /* Soft Off-White */
}

.legend-5 {
  background-color: #F4A261;
  color: #333333; /* Dark Grey */
}

.legend-6 {
  background-color: #2A9D8F;
  color: #F5F5F5; /* Soft Off-White */
}

.legend-7 {
  background-color: #264653;
  color: #F5F5F5; /* Soft Off-White */
}

.legend-8 {
  background-color: #E9C46A;
  color: #333333; /* Dark Grey */
}

.legend-9 {
  background-color: #8D99AE;
  color: #F5F5F5; /* Soft Off-White */
}
